import { PropertySalesTabOption, tPropertySalesReducerState } from './types';

export const initialState: tPropertySalesReducerState = {
    property_sales_count: undefined,
    property_sales_missing_coh_count: undefined,
    percent_missing_coh: undefined,
    compliance_rate: undefined,

    property_sales: [],

    loading: false,

    pagination: {
        pageIndex: 0,
        pageSize: 10,
    },

    searchData: {
        recordYear: new Date().getFullYear() - 1,
        tabOption: PropertySalesTabOption.ALL_PROPERTY_SALES,
    },
};

import { AxiosResponse } from 'axios';
import { assetApiClient, makeSignedGetRequest } from 'src/api';
import { extractCOHCsvFilenameFromHeaders } from 'src/helpers';
import {
    makePropertySalesSearchQueryString,
    PropertySalesTabOption,
    tPropertySalesSearchData,
    UserAccounts,
} from 'src/store';
import { userCanAccessCoHTab } from './auth';

export const downloadCsv = async (
    slug: string | undefined,
    searchData: tPropertySalesSearchData,
    user?: UserAccounts
) => {
    try {
        if (slug == null) {
            return;
        }
        let csv: AxiosResponse;

        const queryString = makePropertySalesSearchQueryString(searchData.recordYear);
        if (searchData.tabOption === PropertySalesTabOption.PROPERTY_SALES_MISSING_COH) {
            if (!userCanAccessCoHTab(user, slug) || !user) {
                console.error('user account not found or user permissions are insufficienct');
                return;
            }
            csv = await makeSignedGetRequest(
                assetApiClient,
                user.thirdwebAccount,
                `/property-sales-missing-coh/${slug}/csv${queryString}`
            );
        } else {
            csv = await assetApiClient.get(`/property-sales/${slug}/csv${queryString}`);
        }

        const filename = extractCOHCsvFilenameFromHeaders(csv.headers);
        const url = window.URL.createObjectURL(new Blob([csv.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        link.parentNode?.removeChild(link);
    } catch (e) {
        console.error('An error occurred while trying to download a file.', e);
    }
};

import {
    tPropertySaleSummary,
    tPropertySalesMissingCOHSummary,
    tPropertySalesSummary,
    tSearchPagination,
    tSearchPaginationState,
} from 'src/types';

import {
    CLEAR_PROPERTY_SALES,
    GET_ALL_PROPERTY_SALES,
    GET_ALL_PROPERTY_SALES_MISSING_COH,
    SET_PROPERTY_SALES_SEARCH_DATA,
    SET_PROPERTY_SALES_LOADING,
    SET_PROPERTY_SALES_PAGINATION,
} from './action-types';
import { LOGOUT_USER } from '../user/action-types';

export type tSetPropertySalesPaginationAction = {
    type: typeof SET_PROPERTY_SALES_PAGINATION;
    payload: {
        pagination: tSearchPagination;
    };
};

export type tClearPropertySalesAction = {
    type: typeof CLEAR_PROPERTY_SALES;
    payload: Record<string, never>;
};

export type tLogout = {
    type: typeof LOGOUT_USER;
    payload: Record<string, never>;
};

export type tSetPropertySalesLoadingAction = {
    type: typeof SET_PROPERTY_SALES_LOADING;
    payload: {
        loading: boolean;
    };
};

export type tGetAllPropertySalesAction = {
    type: typeof GET_ALL_PROPERTY_SALES;
    payload: {
        loading: boolean;
        data: tPropertySalesSummary;
    };
};

export type tGetAllPropertySalesMissingCOHAction = {
    type: typeof GET_ALL_PROPERTY_SALES_MISSING_COH;
    payload: {
        loading: boolean;
        data: tPropertySalesMissingCOHSummary;
    };
};

export type tSetPropertySalesSetSearchDataAction = {
    type: typeof SET_PROPERTY_SALES_SEARCH_DATA;
    payload: {
        recordYear?: number;
        tabOption?: PropertySalesTabOption;
    };
};

export type tPropertySalesAction =
    | tSetPropertySalesPaginationAction
    | tClearPropertySalesAction
    | tSetPropertySalesLoadingAction
    | tGetAllPropertySalesAction
    | tGetAllPropertySalesMissingCOHAction
    | tSetPropertySalesSetSearchDataAction
    | tLogout;

export type tPropertySalesReducerState = {
    property_sales_count?: number;
    property_sales_missing_coh_count?: number;
    percent_missing_coh?: number;
    compliance_rate?: number;

    property_sales: tPropertySaleSummary[];

    loading: boolean;

    pagination: tSearchPaginationState;
    searchData: tPropertySalesSearchData;
};

export type tPropertySalesSearchData = {
    recordYear: number;
    tabOption: PropertySalesTabOption;
};

export type tPropertySalesSearchOptions = {
    start_date?: string;
    end_date?: string;
};

export enum PropertySalesTabOption {
    ALL_PROPERTY_SALES = 'ALL_PROPERTY_SALES',
    PROPERTY_SALES_MISSING_COH = 'PROPERTY_SALES_MISSING_COH',
}

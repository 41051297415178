import moment from 'moment';
import { tPropertySalesSearchOptions } from 'src/store/';

export enum DateTimeFormats {
    YYYYMMDD = 'YYYY-MM-DD',
    MMDDYY = 'MM/DD/YY',
    MMDDYYYY = 'MM/DD/YYYY',
    YYYY = 'YYYY',
    HHMMSS = 'HH:mm:ss',
}

export const formatPropertySalesDateRange = (recordYear: number): tPropertySalesSearchOptions => {
    const year = moment(recordYear, DateTimeFormats.YYYY);
    if (!year.isValid()) {
        return {};
    }
    return {
        start_date: year.startOf('year').format(DateTimeFormats.YYYYMMDD),
        end_date: year.endOf('year').format(DateTimeFormats.YYYYMMDD),
    };
};

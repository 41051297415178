import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer';

import { updateReducerPaginationState } from 'src/helpers';

import {
    CLEAR_PROPERTY_SALES,
    GET_ALL_PROPERTY_SALES,
    GET_ALL_PROPERTY_SALES_MISSING_COH,
    SET_PROPERTY_SALES_LOADING,
    SET_PROPERTY_SALES_PAGINATION,
    SET_PROPERTY_SALES_SEARCH_DATA,
} from './action-types';
import { initialState } from './initial-state';
import { PropertySalesTabOption, tPropertySalesAction, tPropertySalesReducerState } from './types';

// TODO: The user reducer should type its actions, and the type
// of the LOGOUT_USER action should be imported here instead of importing
// the action type.
import { LOGOUT_USER } from 'src/store/user/action-types';

const propertySalesPersistConfig = {
    key: 'propertySalesState',
    storage: storage,
    whitelist: [],
};

export const propertySales = (
    state = initialState,
    action: tPropertySalesAction
): tPropertySalesReducerState => {
    switch (action.type) {
        case CLEAR_PROPERTY_SALES: {
            return { ...initialState, searchData: state.searchData };
        }

        case LOGOUT_USER: {
            if (state.searchData.tabOption === PropertySalesTabOption.PROPERTY_SALES_MISSING_COH) {
                return { ...initialState };
            }
            return { ...state };
        }

        case SET_PROPERTY_SALES_LOADING: {
            return {
                ...state,
                loading: action.payload.loading,
            };
        }

        case SET_PROPERTY_SALES_PAGINATION: {
            return {
                ...state,
                pagination: updateReducerPaginationState(
                    state.pagination,
                    action.payload.pagination
                ),
            };
        }

        case GET_ALL_PROPERTY_SALES_MISSING_COH: {
            const { loading, data } = action.payload;
            return {
                ...state,

                loading: loading,
                property_sales:
                    data.property_sales_missing_coh != null ? data.property_sales_missing_coh : [],

                compliance_rate: data.compliance_rate,
                percent_missing_coh: data.percent_missing_coh,
                property_sales_count: data.property_sales_count,
                property_sales_missing_coh_count: data.property_sales_missing_coh_count,
            };
        }

        case GET_ALL_PROPERTY_SALES: {
            const { loading, data } = action.payload;
            return {
                ...state,

                loading: loading,
                property_sales: data.property_sales != null ? data.property_sales : [],
                property_sales_count: data.property_sales_count,
            };
        }
        case SET_PROPERTY_SALES_SEARCH_DATA: {
            let updatedRecordYear = state.searchData.recordYear;
            if (action.payload.recordYear != null) {
                updatedRecordYear = action.payload.recordYear;
            }
            let updatedTabOption = state.searchData.tabOption;
            if (action.payload.tabOption != null) {
                updatedTabOption = action.payload.tabOption;
            }

            return {
                ...state,

                searchData: { recordYear: updatedRecordYear, tabOption: updatedTabOption },
            };
        }

        default:
            return state;
    }
};

export const propertySalesReducer = persistReducer<
    tPropertySalesReducerState,
    tPropertySalesAction
>(propertySalesPersistConfig, propertySales);

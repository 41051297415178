import { Dispatch } from 'redux';

import { assetApiClient } from 'src/api/apiClient';
import {
    tAssetHouseFull,
    tAssetSaleInfo,
    tHouseFull,
    tSaleInfo,
    tUserUploadedPropertyDocument,
} from 'src/types';

import {
    PROPERTY_DETAILS_CLEAR_PROPERTY_DETAILS,
    PROPERTY_DETAILS_GET_PROPERTY_DETAILS,
    PROPERTY_DETAILS_GET_USER_UPLOADED_PROPERTY_DOCUMENTS,
    PROPERTY_DETAILS_SET_PROPERTY_DETAILS_LOADING,
    PROPERTY_DETAILS_SET_USER_UPLOADED_PROPERTY_DOCUMENTS_LOADING,
} from './action-types';

export const changePropertyDetailsLoading = (loading: boolean) => {
    return {
        type: PROPERTY_DETAILS_SET_PROPERTY_DETAILS_LOADING,
        payload: {
            loading,
        },
    };
};

export const changePropertyDetailsData = (data: tHouseFull, loading: boolean) => {
    return {
        type: PROPERTY_DETAILS_GET_PROPERTY_DETAILS,
        payload: {
            data,
            loading,
        },
    };
};

export const changeUserUploadedPropertyDocumentsLoading = (loading: boolean) => {
    return {
        type: PROPERTY_DETAILS_SET_USER_UPLOADED_PROPERTY_DOCUMENTS_LOADING,
        payload: { loading },
    };
};

export const changeUserUploadedPropertyDocumentsData = (
    data: tUserUploadedPropertyDocument[],
    loading: boolean
) => {
    return {
        type: PROPERTY_DETAILS_GET_USER_UPLOADED_PROPERTY_DOCUMENTS,
        payload: { data, loading },
    };
};

export const clearPropertyDetailsData = () => {
    return {
        type: PROPERTY_DETAILS_CLEAR_PROPERTY_DETAILS,
        payload: {},
    };
};

const reshapeSaleInfo = (assetSaleInfo: tAssetSaleInfo): tSaleInfo => {
    return {
        property_location: assetSaleInfo.property_location_id,
        qual: assetSaleInfo.qualifier,
        date: assetSaleInfo.sale_date,
        price: assetSaleInfo.sale_price,
        book: assetSaleInfo.deed_book,
        page: assetSaleInfo.deed_page,

        block: '',
        buildings: '',
        cl_4_type: '',
        condo: '',
        county: '',
        created_at: '',
        deed_note: '',
        deed_recorder: '',
        district: '',
        estate_id: -3,
        floor_area: '',
        grantee: '',
        grantor: '',
        id: -1,
        land: '',
        lot: '',
        nonusable_code: '',
        nu: 0,
        prop_class: '',
        r_t_f_exempt: '',
        r_t_fee: '',
        ratio: '',
        remarks: '',
        serial_Number: '-2',
        total: '',
        updated_at: '',
        year_built: '',
        year_same_as_deed: '',
    };
};

const reshapeHouse = (assetHouse: tAssetHouseFull): tHouseFull => {
    return {
        address: assetHouse.address,
        estate_type: assetHouse.property_class_name,
        hash: assetHouse.hash || undefined,
        owner: assetHouse.owner_name,
        is_verified: null,
        block: assetHouse.block,
        lot: assetHouse.lot,
        qual: assetHouse.qualifier,
        make_payment_url: assetHouse.tax_payment_url,
        lat: assetHouse.latitude,
        lng: assetHouse.longitude,
        land_description: assetHouse.land_description,
        state: assetHouse.state,
        prior_block: assetHouse.prior_block ? assetHouse.prior_block : '',
        prior_lot: assetHouse.prior_lot ? assetHouse.prior_lot : '',
        year_built: `${assetHouse.year_built}`,
        prior_qual: assetHouse.prior_qualifier,
        taxes: `${assetHouse.total_taxes_usd}`,
        municipality: assetHouse.municipality,
        city_state: `${assetHouse.municipality}, ${assetHouse.state}`,
        square_ft: `${assetHouse.gla_sq_ft}`,
        bldg_desc: assetHouse.building_class,
        building_class: assetHouse.building_class,
        building_description: assetHouse.building_description,
        id: assetHouse.property_location_id,
        zone_pdf_url: assetHouse.zone_pdf_url,
        tax_collection_records: assetHouse.tax_collection_records,

        sale_information: assetHouse.property_sales
            ? assetHouse.property_sales.map(reshapeSaleInfo)
            : [],
        zip: assetHouse.zip,
        contract_address: assetHouse.contract_address,
        token_id: assetHouse.token_id,
        smart_contract_uri: assetHouse.smart_contract_uri,
        chain_id: assetHouse.chain_id,
        liens: assetHouse.liens,
        owner_mailing_address: assetHouse.owner_mailing_address,
        owner_state: assetHouse.owner_state,
        owner_town: assetHouse.owner_town,
        owner_zip: assetHouse.owner_zip,

        photos: assetHouse.photos ? assetHouse.photos.map(photo => photo.url) : null,
        external_photo: assetHouse.external_photo,
        prop_loc: assetHouse.address,
        vcs: '',
        comments: [],
        last_tax_payment_amount: '',
        last_tax_payment_date: '',
        history: [],
        attachments: [],
        recent_activity: '',
        join: '',
        addl_lots: '',
        acreage: '',
        updated: '',
        sty_desc: '',
        deed_book: '',
        deed_page: '',
        account_num: '',
        neigh: '',
        ratio: '',
        mtg_acct: '',
        bank_code: '',
        class_cd: '',
        house_class: assetHouse.property_class,
        style: '',
        epl_desc: '',
        epl_further: '',
        epl_init: '',
        epl_statute: '',
        nu_code: '',
        type_use: '',
        zone: assetHouse.zone_code ? assetHouse.zone_code : '',
        zone_note: assetHouse.zone_note,
        total_taxes_usd: assetHouse.total_taxes_usd,
        vacant: assetHouse.vacant_and_abandoned ? assetHouse.vacant_and_abandoned : null,
        property_documents: assetHouse.property_documents,
        municipality_id: assetHouse.municipality_id,
    };
};

const getAssetHouse = async (slug: string) => {
    const { data } = await assetApiClient.get(`/property/${slug}`);
    if (data.length < 1) {
        throw Error('No property found');
    }
    if (data.property_location_id != null) {
        return reshapeHouse(data);
    }

    const response = reshapeHouse(data[0]);
    return response;
};

export const clearPropertyDetails = () => async (dispatch: Dispatch) => {
    dispatch(clearPropertyDetailsData());
};

export const getPropertyDetailsAsync = (slug: string) => async (dispatch: Dispatch) => {
    dispatch(changePropertyDetailsLoading(true));
    try {
        const assetHouse = await getAssetHouse(slug);
        dispatch(changePropertyDetailsData(assetHouse, false));
    } catch (e) {
        dispatch(changePropertyDetailsLoading(false));
        console.error('error fetching property details', e);
    }
};

export const getUserUploadedPropertyDocumentsAsync =
    (slug: string) => async (dispatch: Dispatch) => {
        dispatch(changeUserUploadedPropertyDocumentsLoading(true));
        try {
            const { data } = await assetApiClient.get(`/property/${slug}/documents`);
            dispatch(changeUserUploadedPropertyDocumentsData(data, false));
        } catch (e) {
            dispatch(changeUserUploadedPropertyDocumentsLoading(false));
            console.error('error fetching property documents', e);
        }
    };

import { useEffect } from 'react';

import { ListViewTable, MainButton, ToplineTable } from 'src/components';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { changePropertySalesPagination } from 'src/store';
import { tSearchPagination } from 'src/types';

import { propertySalesTableColumns, propertySalesTableRowRenderer } from '.';
import { dispatchLoadingCall, downloadCsv } from '../helpers';
import { fractionToPercent } from 'src/helpers';

const TOPLINE_TABLE_PERCENTAGE_PRECISION = 0;

interface IProps {
    slug?: string;
    showToplineTable?: boolean;
}

export const PropertySalesContainer = (props: IProps) => {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector(state => state.userReducer);

    const cohState = useAppSelector(state => state.propertySalesReducer);

    const updatePaginationState = (paginationUpdate: tSearchPagination) => {
        dispatch(changePropertySalesPagination(paginationUpdate));
    };

    const fetchMoreData = (query: Record<string, never>, paginationUpdate?: tSearchPagination) => {
        if (paginationUpdate != null) {
            dispatch(changePropertySalesPagination(paginationUpdate));
        }
    };

    const {
        compliance_rate,
        percent_missing_coh,
        property_sales_count,
        property_sales_missing_coh_count,
        loading,
        property_sales,
        pagination,
        searchData,
    } = cohState;
    const { pageIndex, pageSize } = pagination;
    const { tabOption, recordYear } = searchData;

    const { slug, showToplineTable } = props;

    useEffect(() => {
        if (slug == null) {
            return;
        }
        dispatchLoadingCall(dispatch, tabOption, slug, recordYear, user);
    }, [slug]);

    const downloadCsvOnClick = async () => {
        await downloadCsv(slug, searchData, user);
    };

    const noResultsFound = (
        <div className="property-sales-no-sales-in-range">
            No sales were found in the provided date range.
        </div>
    );

    return (
        <>
            {showToplineTable ? (
                <div className="topline-table-download-links">
                    <div className="topline-stats">
                        <ToplineTable
                            gridItems={[
                                {
                                    title: 'Property Sales Missing COH',
                                    value:
                                        property_sales_missing_coh_count != null
                                            ? `${property_sales_missing_coh_count}`
                                            : '-',
                                    isHighlighted: true,
                                },
                                {
                                    title: 'Property Sales',
                                    value:
                                        property_sales_count != null
                                            ? `${property_sales_count}`
                                            : '-',
                                },
                                {
                                    title: 'Percent Missing COH',
                                    value: percent_missing_coh
                                        ? fractionToPercent(
                                              percent_missing_coh,
                                              TOPLINE_TABLE_PERCENTAGE_PRECISION
                                          )
                                        : '-',
                                    isHighlighted: true,
                                },
                                {
                                    title: 'Compliance Rate',
                                    value: compliance_rate
                                        ? fractionToPercent(
                                              compliance_rate,
                                              TOPLINE_TABLE_PERCENTAGE_PRECISION
                                          )
                                        : '-',
                                    isBig: true,
                                },
                            ]}
                        />
                    </div>
                </div>
            ) : (
                <></>
            )}
            <div className="module-list-view-table-container">
                <ListViewTable
                    municipality_slug={slug}
                    items={property_sales}
                    loading={loading}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    updatePagination={updatePaginationState}
                    resultsCount={property_sales.length}
                    queryParameters={{}}
                    fetchMoreData={fetchMoreData}
                    columnsDefs={propertySalesTableColumns}
                    rowRenderer={propertySalesTableRowRenderer}
                    noDataElement={noResultsFound}
                />
            </div>
            <div className="csv-button-download-row">
                <MainButton buttonType={'primary'} onClick={downloadCsvOnClick}>
                    Download as CSV
                </MainButton>
            </div>
        </>
    );
};

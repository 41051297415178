import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Footer, YearSelect } from 'src/components';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
    PropertySalesTabOption,
    changePropertySalesSearchData,
    getMunicipalityAsync,
} from 'src/store';

import { PropertySalesContainer } from './components';
import { dispatchLoadingCall, userCanAccessCoHTab } from './helpers';

import './PropertySales.scss';

const PROPERTY_SALES_TAB_INDEX = 0;
const COH_COMPLIANCE_TAB_INDEX = 1;

export const PropertySales = () => {
    const { slug } = useParams();
    const dispatch = useAppDispatch();
    const scrollableDiv = useRef<HTMLDivElement>(null);
    const { user } = useAppSelector(state => state.userReducer);

    const cohState = useAppSelector(state => state.propertySalesReducer);
    const { searchData } = cohState;
    const { tabOption } = searchData;

    const tabIndex =
        tabOption === PropertySalesTabOption.ALL_PROPERTY_SALES
            ? PROPERTY_SALES_TAB_INDEX
            : COH_COMPLIANCE_TAB_INDEX;

    const onTabChange = (index: number) => {
        const newPropertySalesTabOption =
            index === PROPERTY_SALES_TAB_INDEX
                ? PropertySalesTabOption.ALL_PROPERTY_SALES
                : PropertySalesTabOption.PROPERTY_SALES_MISSING_COH;
        dispatch(changePropertySalesSearchData(undefined, newPropertySalesTabOption));
    };

    useEffect(() => {
        if (slug) {
            dispatch(getMunicipalityAsync(slug));
        }
    }, [slug]);

    const updateRecordYearOnChange = (newRecordYear: number) => {
        if (slug == null) {
            return;
        }
        dispatchLoadingCall(dispatch, tabOption, slug, newRecordYear, user);
    };

    const selectElem = (
        <YearSelect selectedYear={searchData.recordYear} onYearSelect={updateRecordYearOnChange} />
    );

    return (
        <div
            className="app__page tabbed-property-sales-page bg-grey"
            ref={scrollableDiv}
            id="scrollableDiv"
        >
            <div className="tabbed-property-sales">
                <div className="tabbed-property-sales__header">
                    <div className="tabbed-property-sales__properties-header container">
                        <h1>Property Sales</h1>
                        <div className="tabbed-property-sales-date-selector">{selectElem}</div>
                    </div>
                </div>

                <Tabs
                    focusTabOnClick={false}
                    selectedIndex={tabIndex}
                    onSelect={index => onTabChange(index)}
                >
                    <div className="coh_table__tabs-header-wrapper">
                        <div className="coh_table__tabs-header container">
                            <TabList className="tabbed-property-sales__tab-list">
                                <Tab>All Sales</Tab>
                                {userCanAccessCoHTab(user, slug) ? (
                                    <Tab>COH Compliance</Tab>
                                ) : (
                                    <></>
                                )}
                            </TabList>
                        </div>
                    </div>
                    <div className="tabbed-property-sales__tabs-body-wrapper">
                        <div className="coh_table__tabs-body container">
                            <TabPanel>
                                <PropertySalesContainer slug={slug} />
                            </TabPanel>
                            {userCanAccessCoHTab(user, slug) ? (
                                <TabPanel>
                                    <PropertySalesContainer slug={slug} showToplineTable={true} />
                                </TabPanel>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </Tabs>
            </div>
            <Footer />
        </div>
    );
};

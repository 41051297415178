import { BaseSelect } from 'src/components';

interface IProps {
    selectedYear: number;
    onYearSelect: (newYear: number) => void;
}

const currentYear = new Date().getFullYear();
const DATE_RANGE_LABELS = Array.from({ length: 8 }, (_, i) => currentYear + 2 - i);

export const YearSelect = (props: IProps) => {
    return (
        <BaseSelect
            size="custom-l"
            additionText="Select Year"
            selectedValue={props.selectedYear.toString()}
            items={DATE_RANGE_LABELS.map(year => ({
                id: String(year),
                name: String(year),
            }))}
            onClick={elementId => {
                props.onYearSelect(Number(elementId));
            }}
        />
    );
};
